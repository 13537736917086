<app-base-dialog
  headerText="Step {{ currentStep }}: {{
    wizardHeader[currentStep - 1] | translate
  }}"
>
  <!-- body -->
  <div class="modal-body">
    <!-- loader -->
    <app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>

    <!-- DEBUG -->
    <!-- <pre style="color: white;">{{ form.value | json }}</pre> -->

    <div *ngIf="!loading">
      <!-- channel form  -->
      <div *ngIf="currentStep === 1">
        <app-channel-form
          [profileId]="profileId"
          [isDashboardView]="true"
          [parentFormGroup]="form"
          [formId]="channel"
          [newChannel]="true"
        ></app-channel-form>
      </div>

      <!-- device form  -->
      <div *ngIf="currentStep === 2">
        <app-device-form
          [profileId]="profileId"
          [isInvalidCode]="isInvalidCode"
          [isHideChannelSelection]="true"
          [parentFormGroup]="form"
        ></app-device-form>
      </div>
    </div>
  </div>

  <!-- footer -->
  <div *ngIf="!loading" class="modal-footer d-flex justify-content-between">
    <div>
      <button
        *ngIf="currentStep > 1"
        class="btn btn-primary mx-1"
        (click)="back()"
      >
        {{ 'BACK' | translate }}
      </button>
    </div>

    <div>
      <button class="btn btn-outline-warning mx-1" (click)="cancel()">
        {{ 'CANCEL' | translate }}
      </button>

      <button
        *ngIf="currentStep < TOTAL_STEP"
        class="btn btn-outline-success mx-1"
        [disabled]="form.controls.channel.invalid"
        (click)="next()"
      >
        {{ 'NEXT' | translate }}
      </button>

      <button
        *ngIf="currentStep === 2"
        class="btn btn-success mx-1"
        [disabled]="form.controls.channel.invalid"
        (click)="isSkipProvisionDevice = true; submit()"
      >
        {{ 'SKIP_PROVISIONING' | translate }}
      </button>

      <button
        *ngIf="currentStep === TOTAL_STEP"
        class="btn btn-success mx-1"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        {{ 'PROVISION' | translate }}
      </button>
    </div>
  </div>
</app-base-dialog>
