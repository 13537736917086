import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChannelBulkAction } from '@designage/gql';
import { ChannelRow } from '@desquare/interfaces';
import { CurrentUserService } from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';

@Component({
  standalone: true,
  selector: 'app-channel-bulk-action-dialog',
  imports: [CommonModule, TranslateModule, FormDialogComponent],
  template: `
    <app-form-dialog
      closeButtonText="{{ action | translate }}"
      dismissButtonText="CANCEL"
      headerText="{{ action | translate }}"
      [valid]="channels.length"
    >
      <form id="publishForm">
        <div class="row">
          <div *ngIf="showAppletType" class="d-flex align-items-baseline mb-3">
            <div
              class="badge"
              [class.bg-success]="!isSmilOn"
              [class.bg-danger]="isSmilOn"
            >
              {{ 'CLASSIC' | translate }}
            </div>
            <span class="mx-2 form-check form-switch">
              <input
                type="checkbox"
                class="form-check-input"
                id="toggleSmilCheckbox"
                [checked]="isSmilOn"
                (click)="toggleSmil()"
              />
              <label class="form-check-label" for="toggleSmilCheckbox"></label>
            </span>
            <div
              class="badge"
              [class.bg-success]="isSmilOn"
              [class.bg-danger]="!isSmilOn"
            >
              {{ 'SMIL' | translate }}
            </div>
          </div>

          <div class="col-12 form-group">
            <div *ngIf="!channels.length">
              <p>{{ 'CHANNEL_ACTION_NOT_APPLICABLE' | translate }}</p>
            </div>
            <div *ngIf="channels.length">
              <p>{{ 'CHANNEL_ACTION_APPLICABLE' | translate }}</p>
              <ul>
                <li *ngFor="let channel of channels">{{ channel.name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </app-form-dialog>
  `,
})
export class ChannelBulkActionDialogComponent implements OnInit {
  channels: ChannelRow[] = [];
  action!: ChannelBulkAction;

  extra: string = 'CLASSIC';
  isSmilOn: boolean = false;

  get showAppletType() {
    return (
      this.action === ChannelBulkAction.AppletUpdateVersion &&
      this.currentUserService.isSuperAdmin
    );
  }

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit(): void {
    console.log('bulk action', this.action);
  }

  toggleSmil() {
    this.isSmilOn = !this.isSmilOn;
    this.extra = this.isSmilOn ? 'SMIL' : 'CLASSIC';
  }
}
