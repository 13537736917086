import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
  ViewChild,
  ChangeDetectionStrategy,
  signal,
  effect,
  computed,
} from '@angular/core';
import {
  Channel,
  Maybe,
  ChannelsForChannelListFragment,
  GetProfileChannelsForChannelListGQL,
  GetLocationChannelsForChannelListGQL,
  ChannelBulkAction,
  DevicePowerAction,
  DeleteChannelGQL,
  Device,
  PublishChannelsContentGQL,
  PlaylistStatus,
} from '@designage/gql';
import { SubSink } from 'subsink';
import {
  IDatatableRowActivateArgs,
  ICreateChannelForm,
  ChannelRow,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  DeviceDataService,
  SlidePanelService,
  ChannelService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DeviceCreateDialogComponent } from '@designage/app/device/device-create-dialog/device-create-dialog.component';
import { bulkActionsUtil, styleUtil } from '@desquare/utils';
import { ScreenAppStatus, ScreenStatus } from '@desquare/enums';
import { DeleteChannelDialogComponent } from '@desquare/components/common/src/modals/delete-channel-dialog.component';
import { DeviceStatusInfo } from '@desquare/models';
import { ChannelBulkActionDialogComponent } from '@desquare/components/common/src/modals/channel-bulk-action-dialog.component';
import { CreateChannelWizardComponent } from '@designage/app/channel/create-channel-wizard/create-channel-wizard.component';
import { lastValueFrom, throttleTime } from 'rxjs';
import _, { cloneDeep } from 'lodash';
import { DeviceStatusSummary } from '@desquare/components/common/src/DeviceStatusSummary';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { ChannelsStore, DevicesStore } from '@desquare/stores';
import { TableStatusIndicatorComponent } from '@desquare/components/common/src/designage-data-table/table-status-indicator/table-status-indicator.component';

@Component({
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    TranslateModule,
    RouterOutlet,
    NgbTooltip,
    DesignageDataTableComponent,
    LoaderComponent,
    TableStatusIndicatorComponent,
  ],
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelListComponent implements OnInit, OnDestroy {
  devicesStore = inject(DevicesStore);
  channelsStore = inject(ChannelsStore);
  channelService = inject(ChannelService);

  private subs = new SubSink();
  @ViewChild(DesignageDataTableComponent)
  designageDataTable!: DesignageDataTableComponent;
  @Input() channelGroupId!: string;
  @Input() locationId!: string;
  @Input() hiddenButtons = false;
  @Input() hideAddChannelButton = false;
  @Input() relativeRoute = true;
  @Input() redirectOnSelect = true;
  // @Input() hideLocation = false;
  @Input() hideHeader = false;
  @Input() showBulkAction = true;
  @Input() enableSlidePanel = true;
  @Input() enableCheckbox = true;

  private slidePanelService = inject(SlidePanelService);

  @Output() channelSelect = new EventEmitter<ChannelsForChannelListFragment>();
  @Output() channelOldSelect = new EventEmitter<IDatatableRowActivateArgs>();
  @Output() channelDataTableRowClicked =
    new EventEmitter<ChannelsForChannelListFragment>();

  loaderMessage!: string;
  selectedRows = signal<ChannelRow[]>([]);
  desColumns: IDesignageDataTableColumns[] = [];
  selectionSettings!: Object;
  datatableLoading = false;

  total = 0;

  channelActionsForm!: FormGroup;
  profileId = inject(CurrentUserService).getCurrentProfileId();
  ChannelBulkAction = ChannelBulkAction;
  bulkActions = Object.values(ChannelBulkAction);
  channelActionInProgress = false;
  bulkActionButtonsUtil = bulkActionsUtil;

  channels = this.channelsStore.channels;
  loading = this.channelsStore.loading;
  hasNoData = computed(() => this.channels().length === 0);

  constructor(
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService,
    private encryptionService: EncryptionService,
    private deleteChannelGQL: DeleteChannelGQL,
    private deviceDataService: DeviceDataService,
    private publishChannelsContentGql: PublishChannelsContentGQL
  ) {
    // effect(() => {
    //   console.log('channelDataService effect', this.channelsStore.channels());
    // });
  }

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedChannelId = this.slidePanelService.getPanelComponentId();

  get showAddChannelButton() {
    return (
      this.currentUserService.canManageChannels &&
      !this.channelGroupId &&
      !this.hiddenButtons &&
      !this.hideAddChannelButton
    );
  }

  get bulkActionButtonStyle() {
    return styleUtil.getBulkActionButtonStyle(
      this.channelActionsForm.controls.action.value
    );
  }

  get bulkActionButtonIsEnabled() {
    return (
      (this.selectedRows().length &&
        this.channelActionsForm.controls.action.value) ||
      false
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
    this.initForm();
  }

  initVariables() {
    this.desColumns = [
      {
        fieldName: 'status',
        name: '',
        type: 'template',
        templateRef: 'statusIndicator',
        visible: 'mandatory',
        flex: '0',
        style: 'min-width:3rem; max-width:3rem;',
        disableSorting: true,
      },
      {
        fieldName: 'name',
        name: 'CHANNEL_NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'layout.name',
        name: 'LAYOUT',
        type: 'string',
        visible: true,
        groupable: true,
      },
      {
        fieldName: 'devices',
        name: 'DEVICES',
        type: 'generic-array',
        visible: true,
        disableSorting: true,
        noDataMessage: 'NO_DEVICES_CONNECTED_TO_THIS_CHANNEL',
        arrayHeader: 'DEVICES_CONNECTED_TO_CHANNEL',
        columns: [
          {
            fieldName: 'id',
            name: '',
            type: 'device-status-indicator',
            visible: 'mandatory',
          },
          {
            fieldName: 'name',
            name: 'DEVICE_NAME',
            type: 'string',
            visible: 'mandatory',
          },
          {
            fieldName: 'location.name',
            name: 'LOCATION',
            type: 'object',
            visible: 'mandatory',
          },
          {
            fieldName: 'appVersionStatus',
            name: 'Screen App',
            type: 'string',
            visible: 'mandatory',
          },
        ],
      },
      {
        fieldName: 'playlists',
        name: 'PLAYLISTS',
        type: 'generic-array',
        visible: true,
        disableSorting: true,
        arrayHeader: 'PLAYLISTS_CONNECTED_TO_THIS_CHANNEL',
        noDataMessage: 'NO_PLAYLISTS_CONNECTED_TO_THIS_CHANNEL',
        columns: [
          {
            fieldName: 'name',
            name: 'PLAYLIST_NAME',
            type: 'string',
            visible: 'mandatory',
          },
        ],
      },
      // {
      //   fieldName: 'screenStatus',
      //   name: 'Screen',
      //   type: 'boolean',
      //   visible: true,
      // },
    ];

    // Exclude ResetPosition and AddScreen from the list
    this.bulkActions = this.bulkActions.filter(
      (action) =>
        action !== ChannelBulkAction.ResetPosition &&
        action !== ChannelBulkAction.AddScreen
    );
  }

  initForm() {
    this.channelActionsForm = this.formBuilder.group({
      action: [null],
    });
  }

  initSubscriptions() {
    // this.deviceDataService.initDataLinks({
    //   profileId: this.profileId,
    // });
    // this.getChannels();
  }

  // getChannels() {
  //   if (!this.datatableLoading) {
  //     this.datatableLoading = true;
  //     this.channels.set([]);
  //     if (this.locationId) {
  //       this.getLocationChannels(this.locationId); // issue
  //     } else if (this.profileId) {
  //       this.getProfileChannels(this.profileId);
  //     }
  //   }
  // }

  updateChannelAndDevice(channel: ChannelRow, device: Device) {
    channel.deviceStatus = this.deviceDataService.getDeviceStatus(device.id);
    channel.screenStatus =
      channel.deviceStatus === DeviceStatusInfo.Offline
        ? ScreenStatus.OFFLINE
        : this.deviceDataService.isDeviceScreenPoweredOn(device.id)
        ? ScreenStatus.DISPLAY_ON
        : ScreenStatus.DISPLAY_OFF;
    if (
      channel.isActionInProgress &&
      channel.deviceStatus === DeviceStatusInfo.Online
    ) {
      const msg = channel.actionMessage
        ? channel.actionMessage
        : channel.controlActionMessage;
      const action = this.getActionFromMessage(msg);
    }
  }
  // updateChannels(deviceId?: string) {
  //   let updated = false;
  //   for (const channel of this.channels()) {
  //     if (
  //       !deviceId ||
  //       (deviceId && channel.devices.map((x) => x.id).includes(deviceId))
  //     ) {
  //       const newStatus = this.getChannelStatus(channel);
  //       if (channel.status !== newStatus) {
  //         channel.status = newStatus;
  //         updated = true;
  //       }
  //       // TODO: if updated===true then send next value to the observable (to be implemented)
  //     }
  //   }
  // }

  getDeviceStatus(deviceId: string) {
    return this.devicesStore.getDevice(deviceId);
  }

  getChannelStatus(channel: ChannelsForChannelListFragment) {
    if (!channel.devices || channel.devices.length === 0) {
      return undefined;
    }
    const devIds = channel.devices.map((x) => x.id);
    const devStatuses = devIds.map((x) =>
      this.deviceDataService.getDeviceStatus(x)
    );
    const statusSummary = new DeviceStatusSummary(devStatuses);
    return statusSummary.getMixedStatusValue();
  }

  // getLocationChannels(locationId: string) {
  //   this.subs.sink = this.getLocationChannelsGQL
  //     .watch(
  //       {
  //         id: locationId,
  //       },
  //       { fetchPolicy: 'cache-and-network' }
  //     )
  //     .valueChanges.subscribe({
  //       next: ({ data, loading }) => {
  //         this.datatableLoading = loading;
  //         const { location } = data;

  //         if (location && location.channels) {
  //           this.channels.set(
  //             cloneDeep(this.completeChannelTableData(location.channels))
  //           );
  //           this.total = this.channels.length;
  //         } else {
  //           this.total = 0;
  //           this.channels.set([]);
  //         }
  //       },
  //       error: (error: ApolloError) => {
  //         error.graphQLErrors.forEach((gqlError) => {
  //           console.error('getLocationChannelsGQL error: ', gqlError);
  //           this.toasterService.handleGqlError(gqlError);
  //         });

  //         this.datatableLoading = false;
  //       },
  //     });
  // }

  /** standard channel list */
  // getProfileChannels(profileId: string) {
  // this.channelsStore.getProfileChannelsFromApiPromise();
  /*this.subs.sink = this.getProfileChannelsGQL
      .watch(
        {
          id: profileId,
        },
        { fetchPolicy: 'cache-and-network' }
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          this.datatableLoading = loading;
          const { profile } = data;

          if (profile && profile.channels) {
            /*const filteredChannels = this.filterService.filterListByName(
              this.searchText,
              this.getChannelRows(profile.channels)
            );
            // this.channels.set(
            //   cloneDeep(this.completeChannelTableData(profile.channels))
            ); // filteredChannels;
            // const statuses = [
            //   PlaylistStatus.Published,
            //   PlaylistStatus.ReadyToPublish,
            // ];
            // this.channels().forEach((c) => {
            //   c.playlists = c.playlists.filter((x) =>
            //     statuses.includes(x.status)
            //   );
            // });
            this.total = this.channels().length;
          } else {
            this.total = 0;
            this.channels.set([]);
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getProfileChannelsGQL error: ', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });

          this.datatableLoading = false;
        },
      });*/
  // }

  /**
   * Add Channel Wizard STEP 1
   * @param form null on first call, valued when user goes back from STEP 2
   */
  openCreateChannelDialog(form?: ICreateChannelForm) {
    const modalRef = this.modalService.open(CreateChannelWizardComponent, {
      size: 'xl',
      backdrop: 'static',
    });

    if (this.profileId) {
      modalRef.componentInstance.profileId = this.profileId;
    }

    modalRef.result
      .then((values: ICreateChannelForm) => {
        // this.openProvisionDeviceDialog(values);

        // refresh UI
        this.channelService.refreshChannels();
      })
      .catch(() => {})
      .finally(() => {
        // if (geocoderContainer) geocoderParent?.appendChild(geocoderContainer);
        // if (mapContainer) mapParent?.appendChild(mapContainer);
      });
  }

  navigateToChannel(channel: Channel) {
    if (channel?.id && this.profileId) {
      const id = this.encryptionService.encrypt(channel.id);
      const profileId = this.encryptionService.encrypt(this.profileId);

      if (this.currentUserService.canManageChannels && profileId) {
        this.relativeRoute
          ? this.router.navigate(['manage', id, profileId], {
              relativeTo: this.route,
            })
          : this.router.navigate(['/channels/manage', id, profileId]);
        // this.router.navigate(['/channel/manage', id, profileId]);
      } else if (this.currentUserService.canViewChannels) {
        // TODO: test this use case (not yet tested)
        this.router.navigate(['detail', id], {
          relativeTo: this.route,
        });
        // this.router.navigate(['/channel/detail', id]);
      } else {
        this.toasterService.error('ERROR_USER_NO_PERMISSION');
      }
    } else {
      this.toasterService.error('UNKNOWN_ERROR');
    }
  }

  onRowClick(channel: Channel) {
    if (this.redirectOnSelect) {
      this.slidePanelService.setPanelComponentId(channel.id);
      this.navigateToChannel(channel);
    } else {
      if (channel?.id) {
        this.slidePanelService.setPanelComponentId(channel.id);
        this.channelSelect.emit(channel);
      }
    }
  }

  bulkActionClick(action: ChannelBulkAction) {
    const applicableChannels = this.getApplicableChannels(
      action,
      this.selectedRows()
    );
    const modal = this.modalService.open(ChannelBulkActionDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.channels = applicableChannels;
    modal.componentInstance.action = action;
    modal.result
      .then(() => {
        this.doBulkAction(
          action,
          applicableChannels,
          modal.componentInstance.extra
        );
        this.designageDataTable.selection.clear();
        this.selectedRows.set([]);
      })
      .catch((error) => {
        console.error('error in bulkaction:', error);
      });
  }

  updateSelectedRows(channels: ChannelRow[]) {
    this.selectedRows.set(channels);
  }

  /** starts a bulk action after popup confirmation */
  async doBulkAction(
    action: ChannelBulkAction,
    channels: ChannelRow[],
    extraParam: string
  ) {
    switch (action) {
      case ChannelBulkAction.RepublishChannel:
        await lastValueFrom(
          this.publishChannelsContentGql.mutate({
            input: {
              channelIds: channels.map((x) => x.id),
            },
          })
        );
        break;
      case ChannelBulkAction.AppletUpdateVersion:
        this.datatableLoading = true;
        const deviceIds: string[] = [];
        channels.forEach((c) => {
          c.devices.forEach((d) => {
            deviceIds.push(d.id);
          });
        });
        // by now only a super admin can change applet type
        const override =
          this.currentUserService.isSuperAdmin &&
          (extraParam === 'SMIL' || extraParam === 'CLASSIC');
        const applet = override ? extraParam : 'LATEST';
        await this.deviceDataService.bulkUpdateDeviceApplet(deviceIds, applet);
        this.datatableLoading = false;
        this.channelService.refreshChannels();
        break;
      default:
        for (const channel of channels) {
          this.doChannelAction(action, channel, true, extraParam);
        }
        break;
    }
  }

  completeChannelTableData(rawChannels: ChannelsForChannelListFragment[]) {
    const channelRows: ChannelRow[] = [];
    for (const channel of rawChannels) {
      const device = channel.devices.length ? channel.devices[0] : null;
      const deviceStatus = device
        ? this.deviceDataService.getDeviceStatus(device.id)
        : DeviceStatusInfo.CtrlOffline;
      const screenStatus = !device
        ? ScreenStatus.NOT_AVAILABLE
        : deviceStatus === DeviceStatusInfo.Offline
        ? ScreenStatus.OFFLINE
        : this.deviceDataService.isDeviceScreenPoweredOn(device.id)
        ? ScreenStatus.DISPLAY_ON
        : ScreenStatus.DISPLAY_OFF;
      const appVersion = device?.signageOSDeviceTiming?.appletVersion || '';
      const activeAppVersion =
        device?.signageOSDeviceTiming?.activeAppletVersion || '';
      const versionStatus =
        screenStatus === ScreenStatus.NOT_AVAILABLE
          ? ScreenAppStatus.NOT_AVAILABLE
          : appVersion !== device?.signageOSDeviceTiming?.activeAppletVersion
          ? ScreenAppStatus.OUTDATED
          : ScreenAppStatus.UPDATED;
      const appVersionStatus =
        screenStatus === ScreenStatus.NOT_AVAILABLE
          ? screenStatus
          : `${appVersion} : ${activeAppVersion} : ${versionStatus}`;

      channelRows.push({
        ...channel,
        status: this.getChannelStatus(channel),
        deviceStatus,
        screenStatus,
        appVersionStatus,
        isActionInProgress: false,
        actionMessage: '',
        controlActionMessage: '',
      });
    }

    return channelRows;
  }

  doChannelAction(
    action: ChannelBulkAction,
    channel: ChannelRow,
    confirmed = false,
    extraParam: string = ''
  ) {
    switch (action) {
      case ChannelBulkAction.DisplayOn:
        this.turnScreenOn(channel);
        break;
      case ChannelBulkAction.DisplayOff:
        this.turnScreenOff(channel);
        break;
      case ChannelBulkAction.AppletUpdateVersion:
        this.updateAppVersion(channel, extraParam || 'LATEST');
        break;
      case ChannelBulkAction.AppletReload:
        this.reloadScreenApp(channel);
        break;
      case ChannelBulkAction.AddScreen:
        this.addScreen(channel);
        break;
      case ChannelBulkAction.Reboot:
        this.rebootChannel(channel);
        break;
      case ChannelBulkAction.ClearDeviceCache:
        this.clearCache(channel);
        break;
      case ChannelBulkAction.DeleteChannel:
        if (confirmed) {
          // somebody already confirmed with a popup: delete directly
          this.delete(channel);
        } else {
          this.deleteWithPopup(channel);
        }

        break;
      default: {
        // console.log('UNSUPPORTED ACTION ', action);
      }
    }
  }

  turnScreenOn(channel: ChannelRow) {
    for (const device of channel.devices) {
      this.deviceDataService.turnScreenOn(device.id);
    }
  }
  turnScreenOff(channel: ChannelRow) {
    for (const device of channel.devices) {
      this.deviceDataService.turnScreenOff(device.id);
    }
  }

  async updateAppVersion(channel: ChannelRow, extraParam: string = '') {
    const deviceIds = channel.devices.map((d) => d.id);
    await this.deviceDataService.bulkUpdateDeviceApplet(deviceIds, 'LATEST');
    this.channelService.refreshChannels();
  }

  reloadScreenApp(channel: ChannelRow) {
    for (const device of channel.devices) {
      this.deviceDataService.reloadDeviceApp(device.id);
    }
  }

  addScreen(channel: ChannelRow) {
    const modalRef = this.modalService.open(DeviceCreateDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.channelId = channel.id;
    modalRef.componentInstance.profileId = this.profileId;
    modalRef.componentInstance.areExtraButtonsHidden = true;
    modalRef.componentInstance.isHideChannelSelection = true;

    modalRef.result
      .then(() => {
        // refresh UI
        this.channelService.refreshChannels();
      })
      .catch(() => {})
      .finally(() => {});
  }

  rebootChannel(channel: ChannelRow) {
    for (const device of channel.devices) {
      this.deviceDataService.rebootDevice(device.id);
    }
  }

  clearCache(channel: ChannelRow) {
    for (const device of channel.devices) {
      this.deviceDataService.forceRefreshDeviceContent(device.id);
    }
  }

  /** opens popup to delete a channel */
  deleteWithPopup(channel: ChannelRow) {
    this.openDeleteChannelDialog(channel);
  }

  openDeleteChannelDialog(channel: ChannelRow) {
    if (channel) {
      const modal = this.modalService.open(DeleteChannelDialogComponent, {
        backdrop: 'static',
      });
      modal.componentInstance.channel = channel;
      modal.result
        .then(() => {
          this.delete(channel);
        })
        .catch(() => {})
        .finally(() => {});
    }
  }

  /** delete a channel without showing a popup */
  delete(channel: ChannelRow) {
    if (channel && channel.id) {
      lastValueFrom(
        this.deleteChannelGQL.mutate({
          id: channel.id,
        })
      )
        .then(({ data }) => {
          if (data && data.deleteChannel.isSuccessful) {
            this.channelsStore.removeChannel(channel.id);
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('deleteChannelGQL error: ', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        });
    }
  }

  getApplicableChannels(action: ChannelBulkAction, channels: ChannelRow[]) {
    switch (action) {
      case ChannelBulkAction.AppletReload:
      case ChannelBulkAction.AppletUpdateVersion:
      case ChannelBulkAction.ClearDeviceCache:
      case ChannelBulkAction.DisplayOff:
      case ChannelBulkAction.DisplayOn:
      case ChannelBulkAction.Reboot:
        // Applies only to channels with attached screen/device and online
        return channels.filter(
          (channel) =>
            channel.devices.length &&
            (channel.deviceStatus === DeviceStatusInfo.Online ||
              this.currentUserService.isSuperAdmin)
        );
      case ChannelBulkAction.DeleteChannel:
      case ChannelBulkAction.RepublishChannel:
        // Applies to all channels
        return channels;
      default:
        return [];
    }
  }

  getActionMessage(action: ChannelBulkAction | DevicePowerAction) {
    switch (action) {
      case ChannelBulkAction.AppletUpdateVersion:
      case ChannelBulkAction.AppletReload:
      case DevicePowerAction.AppletReload:
        return 'RELOADING_APP';
      case ChannelBulkAction.AddScreen:
        return 'ADDING_SCREEN';
      case ChannelBulkAction.DisplayOn:
      case DevicePowerAction.DisplayPowerOn:
        return 'TURNING_DISPLAY_ON';
      case ChannelBulkAction.DisplayOff:
      case DevicePowerAction.DisplayPowerOff:
        return 'TURNING_DISPLAY_OFF';
      case ChannelBulkAction.Reboot:
      case DevicePowerAction.SystemReboot:
        return 'REBOOTING';
      case ChannelBulkAction.ClearDeviceCache:
        return 'ClEARING_CACHE';
      case ChannelBulkAction.DeleteChannel:
        return 'DELETING_CHANNEL';
      default:
        return '';
    }
  }

  getActionFromMessage(message: string) {
    switch (message) {
      case 'UPDATING_APP_VERSION':
        return ChannelBulkAction.AppletUpdateVersion;
      case 'RELOADING_APP':
        return ChannelBulkAction.AppletReload;
      case 'ADDING_SCREEN':
        return ChannelBulkAction.AddScreen;
      case 'TURNING_DISPLAY_ON':
        return ChannelBulkAction.DisplayOn;
      case 'TURNING_DISPLAY_OFF':
        return ChannelBulkAction.DisplayOff;
      case 'REBOOTING':
        return ChannelBulkAction.Reboot;
      case 'ClEARING_CACHE':
        return ChannelBulkAction.ClearDeviceCache;
      case 'DELETING_CHANNEL':
        return ChannelBulkAction.DeleteChannel;
      default:
        return undefined;
    }
  }
}
