<div class="d-flex flex-column h-100" [ngClass]="{ 'p-0 m-0': channelGroupId }">
  <div class="d-flex justify-content-between align-items-center">
    @if(!hideHeader && !channelGroupId){
    <h4 class="flex-grow-1">
      {{ 'CHANNELS_CONNECTED_TO_THIS_PROFILE' | translate }}
    </h4>
    }
    <div class="text-end">
      @if(showAddChannelButton){
      <button
        class="btn btn-md btn-outline-primary"
        (click)="openCreateChannelDialog()"
      >
        {{ 'ADD_CHANNEL' | translate }}
      </button>
      }
    </div>
  </div>
  <!-- <button (click)="channelService.refreshChannels()">update data</button> -->
  <designage-data-table
    configId="channel-list"
    [data]="channels()"
    [columns]="desColumns"
    [showBulkActions]="showBulkAction"
    [loading]="loading()"
    [selectedRows]="selectedRows()"
    (selectedRowsChange)="updateSelectedRows($event)"
    [customComponent]="[statusIndicator]"
    [alwaysSort]="true"
    (rowClick)="onRowClick($event)"
    [showMultiSelect]="
      enableCheckbox && !(enableSlidePanel && isSlidePanelOpen())
    "
    [rowActiveInSlidingPanel]="selectedChannelId()"
  >
    <!-- Bulk Actions Template -->
    <ng-container ngProjectAs="bulkActions">
      @for(action of bulkActions; track action){
      <button
        class="my-1 btn btn-sm mx-2"
        (click)="bulkActionClick(action)"
        [ngClass]="bulkActionButtonsUtil.getButtonClass(action).class"
        [ngbTooltip]="
          bulkActionButtonsUtil.getButtonClass(action).toolTip | translate
        "
      >
        {{ action | translate }}
      </button>
      }
    </ng-container>
  </designage-data-table>
  <ng-template #statusIndicator let-row>
    <table-status-indicator [channelRow]="row"></table-status-indicator>
  </ng-template>

  @if(!loading() && hasNoData()){
  <div class="col-12">
    <hr />
    @if(hasNoData()){
    <h4 class="unavailable-message">
      {{ 'NO_CHANNELS_FOUND' | translate }}
    </h4>
    } @if(loading()){
    <h4 class="unavailable-message">
      {{ 'LOADING' | translate }}
    </h4>
    }
  </div>
  }
</div>
<!-- Channel Manage Slide Panel -->
@if(enableSlidePanel){
<router-outlet></router-outlet>
}
